import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { appImages } from '../../constants/images';
import { IoSearch, IoNotificationsSharp, IoSettingsSharp } from 'react-icons/io5';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';
import { navbarGAFunctions } from '../../GAFunctions/NavbarComponent';
import Button from './Button';
import ReactGA from 'react-ga4';
import { useDebounce } from '../../hooks/useDebounce.ts';
import { SearchList } from './SearchList';

export default function Navbar() {
  const { address } = useContext(AuthContext);
  const { setShowWalletConnectModal } = useContext(AppContext);
  const [toggle, setToggle] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchList, setShowSearchList] = useState(false);

  const searchContainerRef = useRef(null);
  const searchListContainerRef = useRef(null);

  let debouncedValue = useDebounce(searchQuery, 250);

  const clickToggle = () => {
    setToggle(!toggle);
  };

  const connect = () => {
    ReactGA.event({
      action: 'Connected To Metamask',
      category: 'Connect',
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchListContainerRef.current && searchListContainerRef.current.contains(event.target)) {
        return;
      }

      if (searchContainerRef.current && searchContainerRef.current.contains(event.target)) {
        setShowSearchList(true);
        return;
      }

      setShowSearchList(false);
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <header className="header">
        <nav className="header_navbar">
          <Link to="/">
            <span className="header_navbar_logo">
              <img src={appImages.portal} alt="portal" className="header_navbar_logo_img" />
            </span>
          </Link>
          <div className="header_navbar_searchbox" ref={searchContainerRef}>
            <span className="header_navbar_searchbox-icon">
              <IoSearch />
            </span>
            <input
              type="search"
              name="search"
              id="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search the Games..."
              autoComplete="off"
              className="header_navbar_searchbox-input"
            />
            <div className="search-result-container" ref={searchListContainerRef}>
              {debouncedValue && showSearchList ? (
                <SearchList searchQuery={debouncedValue} setShowSearchList={setShowSearchList} />
              ) : null}
            </div>
          </div>
          <div className={toggle ? 'navbar-right show' : 'navbar-right'}>
            <ul className="header_navbar_navbtnslist">
              <Link to="/upload">
                <li
                  className="header_navbar_navbtnslist_listitems"
                  onClick={() => {
                    navbarGAFunctions.uploadButtonEvent();
                    setToggle(!toggle);
                  }}
                >
                  Upload Games
                </li>
              </Link>
              <Link to="/">
                <li
                  className="header_navbar_navbtnslist_listitems"
                  onClick={() => {
                    navbarGAFunctions.dashboardButtonEvent();
                    setToggle(!toggle);
                  }}
                >
                  Dashboard
                </li>
              </Link>
              {address ? (
                <Link to="/profile">
                  <li
                    className="header_navbar_navbtnslist_listitems"
                    onClick={() => {
                      navbarGAFunctions.profileButtonEvent();
                      setToggle(!toggle);
                    }}
                  >
                    Profile
                  </li>
                </Link>
              ) : null}
            </ul>
            <div className="header_navbar_icons">
              <span className="header_navbar_icons-notification">
                <IoNotificationsSharp />
              </span>
              <span className="header_navbar_icons-settings">
                <IoSettingsSharp />
              </span>
            </div>
            {address ? (
              <div className="header_navbar_address">
                <p title={address} className="header_navbar_address-address">{`${address.slice(
                  0,
                  5,
                )}.....${address.slice(-4)}`}</p>
                <Link to={`/user/${address}`} className="header_navbar_address-avatar">
                  <span onClick={navbarGAFunctions.profileButtonEvent}>
                    <img src={appImages.avatar} alt="avatar"></img>
                  </span>
                </Link>
              </div>
            ) : (
              <div className="connect-btn">
                <Button
                  text="Connect Wallet"
                  onclick={() => {
                    connect();
                    setShowWalletConnectModal(true);
                    setToggle(!toggle);
                  }}
                />
              </div>
            )}
          </div>
          <span className="toggle" onClick={clickToggle}>
            <img src={appImages.navigation} alt="collection" />
          </span>
        </nav>
      </header>
    </>
  );
}
