import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";
import { backendRoutes } from "../constants/routes";
import EncryptApiKey from "../helpers/EncryptApiKey";

export default function ExploreGames() {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { category } = useParams();

  const openGame = (gURL) => {
    ReactGA.event({
      action: "Game Opened",
      category: "Games",
      label: `/play-game/${gURL}`,
    });
  };

  const [games, setGames] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function getGames() {
      const encryptKey = await EncryptApiKey();

      const fetchUrl =
        category === "all"
          ? `${backendRoutes.allGames}?all=true`
          : `${backendRoutes.allGames}/${category}?all=true`;

      const requestOptions = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "x-api-key": encryptKey,
        },
      };

      try {
        const response = await fetch(fetchUrl, requestOptions);

        if (response.ok) {
          const data = await response.json();

          if (category === "all") {
            setGames(data.gamesAll);
          } else {
            setGames(data.gamesByCategory);
          }
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    }

    getGames();
  }, [category]);
  function EncryptAndNavigate(game) {
    var encryptUrl = btoa(game.gameUrl);
    navigate(`/play-game/${encryptUrl}`, { state: { game: game } });
  }
  return (
    <>
      <div className="games">
        <h1 className="games_heading">Explore Games</h1>
        <div className="games_container">
          <ul className="games_container_categoryBtns">
            <li
              className={category === "all" ? "active" : ""}
              onClick={() => {
                navigate(`/games/all`, { state: { category: "all" } });
              }}
            >
              All
            </li>
            <li
              className={category === "hyper-casual" ? "active" : ""}
              onClick={() => {
                navigate(`/games/hyper-casual`, {
                  state: { category: "hyper-casual" },
                });
              }}
            >
              Hyper Casual
            </li>
            <li
              className={category === "casual" ? "active" : ""}
              onClick={() => {
                navigate(`/games/casual`, { state: { category: "casual" } });
              }}
            >
              Casual
            </li>
            <li
              className={category === "rpg" ? "active" : ""}
              onClick={() => {
                navigate(`/games/rpg`, { state: { category: "rpg" } });
              }}
            >
              RPG
            </li>
            <li
              className={category === "racing" ? "active" : ""}
              onClick={() => {
                navigate(`/games/racing`, { state: { category: "racing" } });
              }}
            >
              Racing
            </li>
            <li
              className={category === "others" ? "active" : ""}
              onClick={() => {
                navigate(`/games/others`, { state: { category: "others" } });
              }}
            >
              Others
            </li>
          </ul>

          {!isLoading ? (
            <main className="games_container_main">
              {games && games.length > 0
                ? games.map(function (game) {
                    return (
                      <div
                        onClick={() => {
                          openGame();
                          EncryptAndNavigate(game);
                        }}
                        key={game._id}
                        className="games_container_main_game"
                      >
                        <div className="games_container_main_game_name">
                          {game.name}
                        </div>
                        <span className="games_container_main_game_thumbnail">
                          <img
                            loading="lazy"
                            src={`https://ipfs.io/ipfs/${game.thumbnail}`}
                            alt={game.name}
                          />
                        </span>
                      </div>
                    );
                  })
                : null}
            </main>
          ) : (
            <div className="loading exploreLoad">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
