/** -------------------------------------------------------- */
export const STATE_ACTIONS = {
  SET_FILE_DRAG_ACTIVE: 'set-file-drag-active',
  SET_THUMBNAIL_DRAG_ACTIVE: 'set-thumbnail-drag-active',
  SET_THUMBNAIL_PREVIEW: 'set-thumbnail-preview',
  SET_GAME_FILES: 'set-game-files',
  SET_GAME_NAME: 'set-game-name',
  SET_GAME_DESCRIPTION: 'set-game-description',
  SET_UPLOADING: 'set-uploading',
  SET_GAME_UPLOADED: 'set-game-uploaded',
  SET_GAME_URL: 'set-game-url',
  SET_UPLOADING_SERVICE: 'set-uploading-service',
  SET_THUMBNAIL: 'set-thumbnail',
  SET_CATEGORY: 'set-category',
  RESET_STATES: 'reset-states',
};

/** -------------------------------------------------------- */
export const initialStates = {
  fileDragActive: false,
  thumbnailDragActive: false,
  thumbnailPreview: null,
  gameFiles: null,
  gameName: '',
  gameDescription: '',
  uploading: false,
  gameUploaded: false,
  gameUrl: null,
  uploadingService: '',
  thumbnail: null,
  category: null,
};

/** -------------------------------------------------------- */
export function reducer(state, action) {
  switch (action.type) {
    case STATE_ACTIONS.SET_FILE_DRAG_ACTIVE: {
      return { ...state, fileDragActive: action.payload };
    }

    case STATE_ACTIONS.SET_THUMBNAIL_DRAG_ACTIVE: {
      return { ...state, thumbnailDragActive: action.payload };
    }

    case STATE_ACTIONS.SET_THUMBNAIL_PREVIEW: {
      return { ...state, thumbnailPreview: action.payload };
    }

    case STATE_ACTIONS.SET_GAME_FILES: {
      return { ...state, gameFiles: action.payload };
    }

    case STATE_ACTIONS.SET_GAME_NAME: {
      return { ...state, gameName: action.payload };
    }

    case STATE_ACTIONS.SET_GAME_DESCRIPTION: {
      return { ...state, gameDescription: action.payload };
    }

    case STATE_ACTIONS.SET_UPLOADING: {
      return { ...state, uploading: action.payload };
    }
    case STATE_ACTIONS.SET_GAME_UPLOADED: {
      return { ...state, gameUploaded: action.payload };
    }

    case STATE_ACTIONS.SET_GAME_URL: {
      return { ...state, gameUrl: action.payload };
    }

    case STATE_ACTIONS.SET_UPLOADING_SERVICE: {
      return { ...state, uploadingService: action.payload };
    }

    case STATE_ACTIONS.SET_THUMBNAIL: {
      return { ...state, thumbnail: action.payload };
    }

    case STATE_ACTIONS.SET_CATEGORY: {
      return { ...state, category: action.payload };
    }

    case STATE_ACTIONS.RESET_STATES: {
      return initialStates;
    }

    default: {
      return state;
    }
  }
}
