import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

function PrivateRoute({ children }) {
  const address = localStorage.getItem('walletAddress');
  const { setShowWalletConnectModal } = useContext(AppContext);
  if (!address) {
    setShowWalletConnectModal(true);
    return <Navigate to="/" />;
  }
  return children;
}

export default PrivateRoute;
