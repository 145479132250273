import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheets/css/main.css';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
