import { useContext } from 'react';

import { appImages } from '../../constants/images';
import { walletConnect } from '../../helpers/WalletConnect';

import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';

import { RiCloseCircleFill } from 'react-icons/ri';

export function WalletConnectModal() {
  const { setAddress } = useContext(AuthContext);
  const { setShowWalletConnectModal } = useContext(AppContext);

  return (
    <>
      <main className="modal">
        <div className="modal_modalContainer" onClick={(event) => event.stopPropagation()}>
          <span className="modal_modalContainer_closeBtn" onClick={() => setShowWalletConnectModal(false)}>
            <RiCloseCircleFill />
          </span>
          <h1 className="modal_modalContainer_modalHeading">Connect Wallet to start using portal</h1>
          <div className="modal_modalContainer_ctaBtnsContainer">
            <button
              className="modal_modalContainer_ctaBtnsContainer_ctaBtns metamaskBtn"
              onClick={async () => {
                let currentAddress = await walletConnect(setShowWalletConnectModal);
                setAddress(currentAddress);
              }}
            >
              <span className="modal_modalContainer_ctaBtnsContainer_ctaBtns_btnLogo">
                <img src={appImages.metamask} alt="metamask" />
              </span>
              Connect With Metamask
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
