import ReactGA from 'react-ga4';

export const navbarGAFunctions = {
  dashboardButtonEvent: function () {
    ReactGA.event({
      action: 'Dashboard',
      category: 'Navbar',
    });
  },

  uploadButtonEvent: function () {
    ReactGA.event({
      action: 'Upload',
      category: 'Navbar',
    });
  },

  profileButtonEvent: function () {
    ReactGA.event({
      action: 'Profile',
      category: 'Navbar',
    });
  },
};
