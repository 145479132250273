import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import FetchABI from "./GetABI";
import SignerFn from "./SignerFn";
import marketPlaceContractABI from "./MarketplaceABI.json";
import CheckChain from "../helpers/CheckChain";

async function ListNFT(contractAddress, TokenID, listingPrice) {
  await CheckChain();
  try {
    const abi = await FetchABI(contractAddress, "polygon");
    const nftContract = new ethers.Contract(contractAddress, abi, SignerFn);
    const marketPlaceContract = new ethers.Contract(
      marketPlaceContractABI.contractAddress,
      marketPlaceContractABI.abi,
      SignerFn
    );

    let tx = await nftContract.approve(
      marketPlaceContractABI.contractAddress,
      TokenID
    );
    let nfttx = await tx.wait();
    // let price = 0.02;
    let tx2 = await marketPlaceContract.listItem(
      contractAddress,
      parseInt(0),
      TokenID,
      parseInt(1),
      ethers.utils.parseEther(listingPrice.toString())
    );
    let nfttx2 = await tx2.wait();
    toast.success("Item listed", nfttx2);

    window.location.reload();
  } catch (error) {
    throw Error(error);
  }
}

export default ListNFT;
