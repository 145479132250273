import { IoCloudDone } from 'react-icons/io5';
import { RiFolderZipFill } from 'react-icons/ri';
import { SlArrowUpCircle } from 'react-icons/sl';

/** -------------------------------------------------------- */
export function GameUploading({ uploadingService }) {
  return (
    <>
      <span className="upload_icons upload_leftContainer_dropzone_uploadingIcon">
        <SlArrowUpCircle />
      </span>
      <p className="upload_leftContainer_dropzone_uploadingMsg">
        {uploadingService === 'ipfs'
          ? 'Uploading on IPFS'
          : uploadingService === 'web3'
          ? 'Uploading on Web3 Storage'
          : 'Uploading on Lighthouse'}
      </p>
      <p className="upload_leftContainer_dropzone_uploadingMsg">
        {uploadingService === 'ipfs'
          ? 'The InterPlanetary File System is a protocol, hypermedia and file sharing peer-to-peer network for storing and sharing data in a distributed file system.'
          : uploadingService === 'web3'
          ? 'At the core of web3.storage is a storage service to safely secure and make your data available - giving developers the power of decentralized storage and content addressing via simple client libraries or an HTTP API.'
          : 'Lighthouse is a blockchain startup allowing users to store files in a decentralised manner, where customers pay only once and store files forever.'}
      </p>
      <div className="upload_leftContainer_dropzone_uploader">
        <div></div>
      </div>
    </>
  );
}

/** -------------------------------------------------------- */
export function GameUploadComplete({ gameFiles, uploadingService, message }) {
  return (
    <>
      <span className="upload_icons upload_leftContainer_dropzone_uploadDoneIcon">
        <IoCloudDone />
      </span>
      {message ? (
        <div className="upload_leftContainer_dropzone_uploadedFileInfo_infoMsg">{message}</div>
      ) : (
        <div className="upload_leftContainer_dropzone_uploadedFileInfo">
          <span className="upload_leftContainer_dropzone_uploadedFileInfo_fileName">
            <RiFolderZipFill className="zip-folder-icon" />
            {gameFiles}
          </span>
          <div className="upload_leftContainer_dropzone_uploadedFileInfo_infoMsg">
            {uploadingService === 'ipfs'
              ? 'Uploaded on IPFS'
              : uploadingService === 'web3'
              ? 'Uploaded on Web3 Storage'
              : 'Uploaded on Lighthouse'}
          </div>
        </div>
      )}
    </>
  );
}
