export default function Button(props) {
  return (
    <>
      <button
        disabled={props.disabled}
        type={props.type ? props.type : 'button'}
        className="appbtn"
        onClick={props.onclick}
      >
        {props.text}
      </button>
    </>
  );
}
