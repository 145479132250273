import ReactGA from 'react-ga4';

export const UploadGAFunctions = {
  thumbnailUploadEvent: function () {
    ReactGA.event({
      action: 'Thumbnail Upload',
      category: 'Upload',
    });
  },

  uploadByWeb3Event: function () {
    ReactGA.event({
      action: 'Upload By Web3',
      category: 'Upload',
    });
  },

  uploadOnIPFSEvent: function () {
    ReactGA.event({
      action: 'Upload on IPFS ',
      category: 'Upload',
    });
  },

  launchEvent: function () {
    ReactGA.event({
      action: 'Launch',
      category: 'Launch',
    });
  },
};
