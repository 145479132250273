import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import FetchABI from "./GetABI";
import SignerFn from "./SignerFn";
import marketPlaceContractABI from "./MarketplaceABI.json";
import CheckChain from "../helpers/CheckChain";

async function CancelListing(orderID) {
  await CheckChain();

  try {
    const marketPlaceContract = new ethers.Contract(
      marketPlaceContractABI.contractAddress,
      marketPlaceContractABI.abi,
      SignerFn
    );
    let tx = await marketPlaceContract.cancelListing(orderID);
    await tx.wait();
    setTimeout(() => {
      toast.success("Listing Cancelled ");
      window.location.reload();
    }, 5000);
    return;
  } catch (error) {
    throw Error(error);
  }
}

export default CancelListing;
