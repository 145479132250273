import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga4";
import { AppContext } from "./context/AppContext";

import Navbar from "./components/common/Navbar";
import UploadGames from "./pages/Upload";
import Home from "./pages/Home";
import PrivateRoute from "./route/PrivateRoute";
import UserAllGame from "./pages/UserAllGame";
import ExploreGames from "./pages/ExploreGames";
import PlayGameNew from "./pages/PlayGameNew";
import { WalletConnectModal } from "./components/common/Modals";
import { useContext } from "react";
import UserProfile from "./pages/Profile";

function App() {
    ReactGA.initialize("G-TBNCRGHP6L");
    ReactGA.send("Home");

    const { showWalletConnectModal } = useContext(AppContext);

    return (
        <>
            <div className="app">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/upload" element={<UploadGames />} />
                    <Route path="/play-game/:url" element={<PlayGameNew />} />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <UserAllGame />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/:walletAddress"
                        element={<UserProfile />}
                    />
                    <Route path="/games/:category" element={<ExploreGames />} />
                    <Route path="*" element={<h1>404</h1>} />
                </Routes>
            </div>
            {showWalletConnectModal ? <WalletConnectModal /> : null}
            <ToastContainer />
        </>
    );
}

export default App;
