import Web3 from "web3";

import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { ethers } from "ethers";
import CheckChain from "./CheckChain";
import EncryptApiKey from "./EncryptApiKey";
async function DeployAndVerifyContract(
  name,
  symbol,
  uri,
  gameName,
  address,
  gameUrl
) {
  if (!name) {
    throw Error("Please Pass Contract Name");
  }
  if (!symbol) {
    throw Error("Please Pass Contract Symbol");
  }
  if (name.includes(" ") || symbol.includes(" ")) {
    throw Error("Name or Symbol can't contain spaces.");
  }
  let metamaskProvider;

  if (window.ethereum.providers === undefined) {
    metamaskProvider = window.ethereum;
  } else {
    metamaskProvider = window.ethereum.providers.find(
      (provider) => provider.isMetaMask
    );
  }

  await CheckChain();
  const encryptKey = await EncryptApiKey();

  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_HOST}contract/compile`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": encryptKey,
      },
      body: JSON.stringify({
        name: name,
        symbol: symbol,
        uri: uri,
      }),
    }
  );
  if (res.status === 200) {
    const response = await res.json();
    const provider = new ethers.providers.Web3Provider(metamaskProvider, "any");
    if (provider?.getSigner() == null) {
      await provider.send("eth_requestAccounts", []);
    }
    const signer = provider.getSigner();
    const ContractFactory = new ethers.ContractFactory(
      response.abi,
      response.bytecode,
      signer
    );
    const contract = await ContractFactory.deploy();
    await contract.deployTransaction.wait(5);
    await contract.deployed();
    const encryptKey = await EncryptApiKey();

    const verifyres = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}contract/verify-contract`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": encryptKey,
        },
        body: JSON.stringify({
          verifyId: response.verifyId,
          contract_address: contract.address,
          gameName: gameName,
          tokenId: "1",
          gameUrl: gameUrl,
        }),
      }
    );
    if (verifyres.status == 200) {
      const verifyResData = await verifyres.json();
      if (verifyResData.result.status == 1) {
        toast.success(contract.address + " Contract Deploy Successfully");
        setTimeout(() => {
          window.location.reload();
        }, [2000]);
        return;
      } else {
        throw Error(verifyres.msg.result);
      }
    } else {
      throw Error("something went wrong whlie verifying contract");
    }
  } else {
    throw Error("something went wrong please try again !!! ");
  }
}

export default DeployAndVerifyContract;
