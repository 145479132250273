import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
const networkName = localStorage.getItem("networkName");
let APIURL;
  APIURL =
    "https://api.thegraph.com/subgraphs/name/lokesh-lync/lync-subgraph";

const client = new ApolloClient({
  uri: APIURL,
  cache: new InMemoryCache(),
});

async function fetchClientQuery(listingsQuery) {
  let response = await client.query({
    query: gql(listingsQuery),
  });
  return response;
}

export { APIURL, client, gql, fetchClientQuery };
