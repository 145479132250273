import { ethers } from "ethers";
import { toast } from "react-toastify";
import SignerFn from "./SignerFn";
import marketPlaceContractABI from "./MarketplaceABI.json";
import { WalletConnectModal } from "../components/common/Modals";
import CheckChain from "../helpers/CheckChain";

async function BuyNFT(orderID, listingPrice) {
    await CheckChain();
    try {
        const marketPlaceContract = new ethers.Contract(
            marketPlaceContractABI.contractAddress,
            marketPlaceContractABI.abi,
            SignerFn
        );
        let tx = await marketPlaceContract.buyItem(orderID, parseInt(1), {
            value: ethers.utils.parseEther(listingPrice),
        });
        await tx.wait();
        setTimeout(() => {
            toast.success("Congratulation YOU BUY NEW NFT ");
            window.location.reload();
        }, 5000);
    } catch (error) {
        throw Error(error.message);
    }
}

export default BuyNFT;
