import ReactGA from 'react-ga4';

export const ProfileGAFunctions = {
  createNewProfileEvent: function () {
    ReactGA.event({
      action: 'Create Profile',
      category: 'Profile',
    });
  },
  editProfileEvent: function () {
    ReactGA.event({
      action: 'Edit Profile',
      category: 'Profile',
    });
  },
  saveProfileEvent: function () {
    ReactGA.event({
      action: 'Save Profile',
      category: 'Profile',
    });
  },
};
