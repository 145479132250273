import ERC721ABI from "../helpers/ERC721ABI.json";

export default async function useGetABI(contractAddress, name) {
  /*let abiData;
    let url;
    if (name === "polygon") {
      url = `https://api-testnet.polygonscan.com/api?module=contract&action=getabi&address=${contractAddress}&apikey=F9EWRRMKH6TRH9YY6FZVPTTUZV745D7CQS`;
    }
    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        abiData = data.result;
      });*/

  return ERC721ABI;
}
