const addGame = `${process.env.REACT_APP_BACKEND_HOST}add-game`;
const allGames = `${process.env.REACT_APP_BACKEND_HOST}all-games`;
const checkGameName = `${process.env.REACT_APP_BACKEND_HOST}check-name`;
const createUser = `${process.env.REACT_APP_BACKEND_HOST}create-user`;
const storeCount = `${process.env.REACT_APP_BACKEND_HOST}store-count`;
const uploadByWeb3 = `${process.env.REACT_APP_BACKEND_HOST}upload-by-web3`;
const uploadOnIPFS = `${process.env.REACT_APP_BACKEND_HOST}upload`;
const uploadOnLighthouse = `${process.env.REACT_APP_BACKEND_HOST}lighthouse`;
const userData = `${process.env.REACT_APP_BACKEND_HOST}user-data`;
const userGames = `${process.env.REACT_APP_BACKEND_HOST}user-games`;
const deleteGame = `${process.env.REACT_APP_BACKEND_HOST}delete-game`;
const updateGame = `${process.env.REACT_APP_BACKEND_HOST}update-game`;
const topGames = `${process.env.REACT_APP_BACKEND_HOST}top-games`;
const searchGames = `${process.env.REACT_APP_BACKEND_HOST}search-games`;
const updateGamePlayCounter = `${process.env.REACT_APP_BACKEND_HOST}update-game-play-count`;

export const backendRoutes = {
  addGame,
  allGames,
  checkGameName,
  createUser,
  storeCount,
  uploadByWeb3,
  uploadOnIPFS,
  uploadOnLighthouse,
  userData,
  userGames,
  deleteGame,
  updateGame,
  topGames,
  searchGames,
  updateGamePlayCounter,
};
