import { toast } from "react-toastify";

async function CheckChain() {
  let metamaskProvider;

  if (!window.ethereum) {
    toast.error("Metamask not detected, Please download Metamask!!");
    return;
  }
  if (window.ethereum.providers === undefined) {
    metamaskProvider = window.ethereum;
  } else {
    metamaskProvider = window.ethereum.providers.find(
      (provider) => provider.isMetaMask
    );
  }
  let chainId = await metamaskProvider.request({ method: "eth_chainId" });
  const polygonChainId = "0x13881";

  if (chainId !== polygonChainId) {
    toast.error("You are not connected to Mumbai Testnet \n");

    try {
      await metamaskProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: polygonChainId }],
      });
      return;
    } catch (error) {
      if (error.code === 4902) {
        toast.error("Please Add Mumbai Testnet \n");
        try {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x13881",
                chainName: "Mumbai Testnet",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC", // 2-6 characters long
                  decimals: 18,
                },
                blockExplorerUrls: ["https://polygonscan.com/"],
                rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
              },
            ],
          });
          return;
        } catch (error) {
          let errordata = error.data
            ? error.data.message.split(":")[1]
            : error.message.split(":")[1];
          toast.error("Please connect your wallet \n");
          console.log("Error:", errordata);
        }
      }
    }
  }
}

export default CheckChain;
