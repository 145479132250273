import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";
import { homeGAFunctions } from "../GAFunctions/HomePage";
import headerVideo from "../assets/header-video.mp4";
import { backendRoutes } from "../constants/routes";
import { SliseAd } from "@slise/embed-react";
import EncryptApiKey from "../helpers/EncryptApiKey";

export default function Home() {
  const navigate = useNavigate();

  const [games, setGames] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [storeCount, setStoreCount] = useState(0);
  const [category, setCategory] = useState("all");
  const [topGames, setTopGames] = useState(null);
  const openGame = (gURL) => {
    ReactGA.event({
      action: "Game Opened",
      category: "Games",
      label: `/play-game/${gURL}`,
    });
  };

  async function getTopGames() {
    const encryptKey = await EncryptApiKey();
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "application/json",
        "x-api-key": encryptKey,
      },
    };

    try {
      const response = await fetch(backendRoutes.topGames, requestOptions);
      if (!response.ok) {
        throw new Error(JSON.stringify(response));
      }

      const data = await response.json();
      setTopGames(data.data);
    } catch (err) {
      const error = JSON.parse(err);
      switch (error?.status) {
        case 400: {
          console.error("Error in fetching top games data: Bad Request");
          break;
        }

        case 401: {
          console.error("Error in fetching top games data: Unautherized");
          break;
        }

        case 500: {
          console.error(
            "Error in fetching top games data: Internal Server Error"
          );
          break;
        }

        default: {
          console.error("Error in fetching top games data: ", err);
          break;
        }
      }

      console.error("Fetching error: ", err);
    } finally {
      setLoading(false);
    }
  }

  async function getStoreCount() {
    const encryptKey = await EncryptApiKey();

    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": encryptKey,
      },
    };

    try {
      const response = await fetch(backendRoutes.storeCount, requestOptions);

      if (response.ok) {
        const { storeCount } = await response.json();
        setStoreCount(storeCount);
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  async function getGamesByCategory() {
    const encryptKey = await EncryptApiKey();

    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": encryptKey,
      },
    };

    try {
      const response = await fetch(
        `${backendRoutes.allGames}/${category}`,
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        setTopGames(data.gamesByCategory);
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    if (category === "all") {
      getTopGames();
    } else {
      getGamesByCategory();
    }
  }, [category]);

  useEffect(() => {
    getStoreCount();
  }, []);

  function EncryptAndNavigate(game) {
    const { gameUrl, url } = game;
    const URL = gameUrl ? gameUrl : url;
    var hashpass = btoa(URL);
    navigate(`/play-game/${hashpass}`, { state: { game: game } });
  }

  return (
    <>
      <div className="home">
        <a
          href="https://itch.io/jam/web-3-game-jam-2"
          target="_blank"
          rel="noopener noreferrer"
          className="registration-link"
        >
          <section className="home_hero">
            <video
              src={headerVideo}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
            <SliseAd
              className="ad"
              slotId="1"
              pub="pub-5"
              format="728x90"
              style={{
                position: "absolute",
                left: "0px",
                bottom: "0px",
                width: "468px",
                height: "60px",
              }}
            />
            <div className="home_hero_content">
              <p>
                Get ready to dive into the world of blockchain gaming <br /> and
                win big:
                <strong>$5000</strong> Prize Pool Up for Grabs!
              </p>

              <button
                className="register-btn gradient-border"
                onClick={() => {
                  homeGAFunctions.registerEvent();
                }}
              >
                Register Here
              </button>
            </div>
          </section>
        </a>
        <div className="home_container">
          <p className="home_container_store">
            Store{" "}
            <span>
              (
              {storeCount ? (
                <>{storeCount}</>
              ) : (
                <>
                  <div className="loading storeLoading">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              )}
              )
            </span>
          </p>
          <div className="home_container_sections">
            <ul className="home_container_sections_points">
              <li
                className={category === "all" ? "active" : ""}
                onClick={() => {
                  setCategory("all");
                  homeGAFunctions.allGames();
                }}
              >
                All
              </li>
              <li
                className={category === "hyper-casual" ? "active" : ""}
                onClick={() => {
                  setCategory("hyper-casual");
                }}
              >
                Hyper Casual
              </li>
              <li
                className={category === "casual" ? "active" : ""}
                onClick={() => {
                  setCategory("casual");
                  homeGAFunctions.casualGames();
                }}
              >
                Casual
              </li>
              <li
                className={category === "rpg" ? "active" : ""}
                onClick={() => {
                  setCategory("rpg");
                  homeGAFunctions.rpgGames();
                }}
              >
                RPG
              </li>
              <li
                className={category === "racing" ? "active" : ""}
                onClick={() => {
                  setCategory("racing");
                  homeGAFunctions.racingGames();
                }}
              >
                Racing
              </li>
              <li
                className={category === "others" ? "active" : ""}
                onClick={() => {
                  setCategory("others");
                }}
              >
                Others
              </li>
            </ul>
            <div className="home_container_sections_viewall">
              <p
                onClick={() => {
                  homeGAFunctions.viewAllButtonEvent();
                  navigate(`/games/${category}`, {
                    state: { category: category },
                  });
                }}
              >
                {" "}
                View All
              </p>
            </div>
          </div>

          {!isLoading ? (
            <div className="home_container_images">
              {topGames ? (
                topGames.length > 0 ? (
                  topGames.map((games, id) => {
                    return (
                      <div
                        className="home_container_images_img"
                        key={id}
                        onClick={() => {
                          openGame();
                          EncryptAndNavigate(games);
                        }}
                      >
                        <img
                          src={`https://ipfs.io/ipfs/${games.thumbnail}`}
                          alt="game-thumbnail"
                          loading="lazy"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="home_container_images_img"
                    style={{ display: "grid", placeItems: "center" }}
                  >
                    No Events have occured yet
                  </div>
                )
              ) : (
                <>
                  <div className="home_container_images_img"></div>
                  <div className="home_container_images_img"></div>
                  <div className="home_container_images_img"></div>
                </>
              )}
            </div>
          ) : (
            <div className="loading homeMain">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
