import ReactGA from 'react-ga4';

export const playGameGAFunctions = {
  buyEvent: function () {
    ReactGA.event({
      action: 'Buy',
      category: 'Play',
    });
  },
  sellEvent: function () {
    ReactGA.event({
      action: 'Sell',
      category: 'Play',
    });
  },
  mintEvent: function () {
    ReactGA.event({
      action: 'Mint',
      category: 'Play',
    });
  },
  cancelEvent: function () {
    ReactGA.event({
      action: 'Cancel',
      category: 'Play',
    });
  },
};
