export const gameCategories = [
  {
    option: 'Hyper Casual',
    value: 'hyper-casual',
  },

  {
    option: 'Casual',
    value: 'casual',
  },

  {
    option: 'RPG',
    value: 'rpg',
  },

  {
    option: 'Racing',
    value: 'racing',
  },

  {
    option: 'Others',
    value: 'others',
  },
];
