import { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { ProfileGAFunctions } from '../GAFunctions/ProfilePage';
import { appImages } from '../constants/images';
import Button from '../components/common/Button';
import { backendRoutes } from '../constants/routes';
import { uploadImageOnNFTStorage } from '../components/upload/UploadFunctions';

import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { func } from 'prop-types';
import EncryptApiKey from '../helpers/EncryptApiKey';

export default function UserProfile() {
  const addressOnLocalStorage = localStorage.getItem('walletAddress');
  const { address } = useContext(AuthContext);
  console.log(address);
  const [isLoading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    name: user?.name,
    email: user?.email,
    url: user?.url,
    twitter: user?.twitter,
    location: user?.location,
    bio: user?.bio,
  });
  const [isDisable, setDisable] = useState(false);
  const [saving, setSaving] = useState(false);
  const [createProfile, setCreateProfile] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const url = URL.createObjectURL(file);

      setProfileImage({
        imageFile: file,
        imageURL: url,
      });
    },
    noDrag: true,
    noClick: true,
  });

  function upDateFormData(e) {
    setFormData((prv) => ({ ...prv, [e.target.id]: e.target.value }));
  }

  async function getUserData() {
    const encryptKey = await EncryptApiKey();

    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': encryptKey,
      },
    };

    const apiData = await fetch(`${backendRoutes.userData}/${address}`, requestOptions);
    const response = await apiData.json();
    if (response.success && response.message === 'User Found') {
      setUser(response.user[0]);
      setFormData({
        name: response?.user[0]?.name,
        email: response?.user[0]?.email,
        url: response?.user[0]?.url,
        twitter: response?.user[0]?.twitter,
        location: response?.user[0]?.location,
        bio: response?.user[0]?.bio,
      });
      if (response.user[0]?.image) {
        setProfileImage({
          imageFile: null,
          imageURL: response.user[0].image,
        });
      } else {
        setProfileImage(null);
      }
    }
    setLoading(false);
  }

  async function updateUserProfile(event) {
    setDisable(true);
    event.preventDefault();
    if (!address) {
      setDisable(false);
      return toast.error('Wallet Address Required');
    }
    let imageUrl = '';
    if (!formData.name || !formData.email || !formData.twitter || !formData.bio) {
      setDisable(false);

      return toast.error('Name, Email, Twitter, Bio., and Wallet Address  is required');
    }

    if (profileImage && profileImage.imageFile) {
      const image = profileImage.imageFile;
      const name = formData.name;
      const description = formData.bio;
      const result = await uploadImageOnNFTStorage({
        image,
        name,
        description,
      });
      if (result.status) {
        const { thumbnailCID } = result.thumbnailData;
        imageUrl = 'https://ipfs.io/ipfs/' + thumbnailCID;
      } else {
        toast.error(result.error);
        imageUrl = '';
      }
    } else if (!profileImage && !profileImage?.imageFile) {
      setDisable(false);

      toast.error('Please Upload Image');
      return;
    }

    try {
      const encryptKey = await EncryptApiKey();

      const response = await fetch(backendRoutes.createUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'x-api-key': encryptKey,
        },
        body: JSON.stringify({
          wallet_address: address,
          name: formData.name,
          email: formData.email,
          url: formData.url,
          twitter: formData.twitter,
          location: formData.location,
          bio: formData.bio,
          image: imageUrl,
        }),
      });
      if (response.ok) {
        const data = await response.json();

        toast.success('Profile updated successfully!');

        setEditProfile(false);
        setDisable(false);
        setSaving(false);
        window.location.reload();
      } else {
        setDisable(false);
      }
    } catch (err) {
      console.error(err.message);
      setDisable(false);
      toast.error('Something went wrong!');
    }
  }

  useEffect(() => {
    if (address) {
      getUserData();
    }
  }, [address]);
  if (!address && !addressOnLocalStorage) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <div className="profile">
        <h1 className="profile_heading">Public Profile</h1>
        <main className="profile_container">
          {!isLoading ? (
            <>
              <div
                {...getRootProps({
                  className: 'profile_container_profileImage',
                })}
              >
                <input className="profile_container_profileImage_inputzone" {...getInputProps()} />
                Profile Picture
                <img src={!profileImage ? appImages.user : profileImage.imageURL} alt="profilepicture" />
                <span className="profile_container_profileImage_editBtn" onClick={open}>
                  <MdModeEdit className="editIcon" />
                  Edit
                </span>
              </div>
              {!user && !createProfile ? (
                <Button
                  type="button"
                  text="+ Create a New Profile"
                  onclick={() => {
                    ProfileGAFunctions.createNewProfileEvent();
                    setCreateProfile(true);
                  }}
                />
              ) : null}
              {user || createProfile ? (
                <form className="profile_container_profileDetails" onSubmit={(event) => updateUserProfile(event)}>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="wallet_address">Wallet Address</label>
                    <input
                      type="text"
                      name="wallet_address"
                      id="wallet_address"
                      value={user ? user.wallet_address : address}
                      readOnly={true}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={user && !editProfile ? user?.name : formData.name}
                      onChange={(e) => upDateFormData(e)}
                      readOnly={(user && !editProfile) || saving}
                      required={true}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={user && !editProfile ? user?.email : formData.email}
                      onChange={(e) => upDateFormData(e)}
                      readOnly={(user && !editProfile) || saving}
                      required={true}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="url">URL</label>
                    <input
                      type="url"
                      name="url"
                      id="url"
                      value={user && !editProfile ? user?.url : formData.url}
                      onChange={(e) => upDateFormData(e)}
                      readOnly={(user && !editProfile) || saving}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="twitter">Twitter</label>
                    <input
                      type="url"
                      name="twitter"
                      id="twitter"
                      value={user && !editProfile ? user?.twitter : formData.twitter}
                      onChange={(e) => upDateFormData(e)}
                      readOnly={(user && !editProfile) || saving}
                      required={true}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      value={user && !editProfile ? user?.location : formData.location}
                      onChange={(e) => upDateFormData(e)}
                      readOnly={(user && !editProfile) || saving}
                    />
                  </div>
                  <div className="profile_container_profileDetails_formControllGroup">
                    <label htmlFor="bio">Bio</label>
                    <textarea
                      name="bio"
                      id="bio"
                      value={user && !editProfile ? user?.bio : formData.bio}
                      onChange={(e) => upDateFormData(e)}
                      rows="6"
                      readOnly={(user && !editProfile) || saving}
                      required={true}
                    ></textarea>
                  </div>
                  <div className="profile_container_profileDetails_formControllBtns">
                    {createProfile || editProfile ? (
                      <div className="save-profile">
                        <Button
                          type="submit"
                          text={
                            isDisable ? (
                              <span className="spin">
                                <div className="loading-spinner"></div>
                              </span>
                            ) : (
                              'Save Profile'
                            )
                          }
                          onclick={() => {
                            setSaving(true);
                            ProfileGAFunctions.saveProfileEvent();
                          }}
                          disabled={isDisable}
                        />
                      </div>
                    ) : null}

                    {createProfile || editProfile ? (
                      <Button type="reset" text="Cancel" disabled={isDisable} onclick={() => setCreateProfile(false)} />
                    ) : null}
                    {user && !editProfile ? (
                      <Button
                        type="button"
                        text="Edit Profile"
                        disabled={isDisable}
                        onclick={() => {
                          ProfileGAFunctions.editProfileEvent();
                          setEditProfile(true);
                        }}
                      />
                    ) : null}
                  </div>
                </form>
              ) : null}
            </>
          ) : (
            <div className="loading">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
