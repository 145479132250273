import portal from '../assets/portal.svg';
import avatar from '../assets/avatar.png';
import homeGame1 from '../assets/home-game-1.png';
import homeGame2 from '../assets/home-game-2.png';
import homeGame3 from '../assets/home-game-3.png';
import metamask from '../assets/metamask.svg';
import walletconnect from '../assets/walletconnect.svg';
import google from '../assets/google.svg';
import itsuki from '../assets/itsuki.png';
import user from '../assets/user.png';
import navigation from '../assets/navigation.png';

export const appImages = {
  portal,
  avatar,
  homeGame1,
  homeGame2,
  homeGame3,
  metamask,
  walletconnect,
  google,
  itsuki,
  user,
  navigation,
};
