import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { backendRoutes } from "../constants/routes";
import EncryptApiKey from "../helpers/EncryptApiKey";

function UserAllGame() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  async function getUserAllData() {
    const address = localStorage.getItem("walletAddress");
    const body = JSON.stringify({
      walletAddress: address,
    });
    try {
      const encryptKey = await EncryptApiKey();

      const response = await fetch(backendRoutes.userGames, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": encryptKey,
        },
        body: body,
      });
      if (!response.ok) {
        throw new Error(JSON.stringify(response));
      }
      const resData = await response.json();
      console.log(resData);
      setData(resData.data);
    } catch (err) {
      console.error("Error in getUserAllData(): ", JSON.parse(err));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUserAllData();
  }, []);
  function EncryptAndNavigate(url) {
    var encryptUrl = btoa(url);
    navigate(`/play-game/${encryptUrl}`);
  }
  return (
    <main className="user">
      <h1 className="user_heading">Your Games</h1>

      {!isLoading ? (
        <div className="user_container">
          {data && data.length > 0 ? (
            data.map((value, i) => {
              return (
                <div
                  onClick={() => EncryptAndNavigate(value.gameUrl)}
                  key={value._id}
                  className="user_container_userGames"
                >
                  <div className="user_container_userGames_name">
                    {value.name}
                  </div>
                  <span className="user_container_userGames_thumbnail">
                    <img
                      loading="lazy"
                      src={`https://ipfs.io/ipfs/${value.thumbnail}`}
                      alt={value.name}
                    />
                  </span>
                </div>
              );
            })
          ) : (
            <div className="no-game">
              <div className="no-game-heading">
                You have not uploaded any game yet
              </div>
              <div className="no-game-btns">
                <Link to="/upload">
                  <button>Upload Your First Game</button>
                </Link>
                <button
                  onClick={() => {
                    navigate(`/games/all`, {
                      state: { category: "all" },
                    });
                  }}
                >
                  Explore Games
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="loading userAllGames">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </main>
  );
}

export default UserAllGame;
