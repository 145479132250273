import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { backendRoutes } from "../../constants/routes";
import { toast } from "react-toastify";
import { BsDashLg } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import EncryptApiKey from "../../helpers/EncryptApiKey";

let skeletonJSX = [];

for (let i = 0; i < 3; i++) {
  skeletonJSX.push(
    <li className="games-list-items skeleton">
      <span className="game-thumbnail"></span>
      <span className="game-information">
        <span className="game-name"></span>
        <span className="game-description"></span>
      </span>
    </li>
  );
}

export const SearchList = ({ searchQuery, setShowSearchList }) => {
  let navigate = useNavigate();

  const [gameData, setGameData] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);
  const [error, setError] = useState(false);

  const { searchGames } = backendRoutes;

  let noDataJSX = (
    <ul className="games-list">
      <li className="games-list-items no-game-item">
        <span className="game-information">
          <span className="no-data-name">
            <IoSearch className="search-icon" />
            <BsDashLg className="dash-icon" />
            {searchQuery}
          </span>
          <span className="no-data-msg">
            Not the results you expected? Try something else!
          </span>
        </span>
      </li>
    </ul>
  );

  const fetchGames = useMemo(
    () => async () => {
      const fetchRoute = `${searchGames}?q=${searchQuery}`;
      const encryptKey = await EncryptApiKey();
      const requestOptions = {
        method: "GET",
        headers: {
          "x-api-key": encryptKey,
          accept: "application/json",
        },
      };

      fetch(fetchRoute, requestOptions)
        .then((response) => {
          console.log("Search games response: ", response);
          if (!response.ok) throw new Error(JSON.stringify(response));

          return response.json();
        })
        .then(({ data }) => {
          setError(false);
          setGameData(data);
        })
        .catch((err) => {
          const responseError = JSON.parse(err);
          console.error(
            "Error in searching games: ",
            responseError.statusCode,
            responseError.message
          );
          setError(true);

          toast.error(
            "Something went wrong! Check browser console for more info."
          );
        })
        .finally(() => setFetchingData(false));
    },
    [searchQuery, searchGames]
  );

  useEffect(() => {
    const runFetchGames = async () => {
      await fetchGames();
    };

    runFetchGames();
  }, [fetchGames]);

  const EncryptAndNavigate = (game) => {
    const { gameUrl, url } = game;
    const URL = gameUrl ? gameUrl : url;
    var hashPass = btoa(URL);
    navigate(`/play-game/${hashPass}`, { state: { game: game } });
    setShowSearchList(false);
  };

  if (fetchingData) {
    return (
      <ul className="games-list">
        {skeletonJSX.map((skeleton, id) => (
          <React.Fragment key={id}>{skeleton}</React.Fragment>
        ))}
      </ul>
    );
  }

  if (error) {
    return noDataJSX;
  }

  if (gameData && gameData?.length === 0) {
    return noDataJSX;
  }

  return (
    <ul className="games-list">
      {gameData.map((game, id) => {
        return (
          <li
            key={id}
            className="games-list-items"
            onClick={() => EncryptAndNavigate(game)}
          >
            <span className="game-thumbnail">
              <img
                src={`https://ipfs.io/ipfs/${game.thumbnail}`}
                alt={game.name}
              />
            </span>
            <span className="game-information">
              <span className="game-name">{game.name}</span>
              <span className="game-description">{game.description}</span>
            </span>
          </li>
        );
      })}
    </ul>
  );
};
