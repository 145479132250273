import { useContext, useReducer, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import { AppContext } from '../context/AppContext';
import { AuthContext } from '../context/AuthContext';

import { UploadGAFunctions } from '../GAFunctions/UploadPage';
import Button from '../components/common/Button';
import { gameCategories } from '../components/upload/GameCategories';
import { STATE_ACTIONS, initialStates, reducer } from '../components/upload/StateManager';
import {
  addGameToDatabase,
  uploadByWeb3,
  uploadOnIPFS,
  uploadOnLighthouse,
} from '../components/upload/UploadFunctions';
import { GameUploadComplete, GameUploading } from '../components/upload/UploadScreens';

import { RiDragDropLine, RiFolderZipFill } from 'react-icons/ri';
import DeployAndVerifyContract from '../helpers/DeployAndVerifyContract';
import EncryptApiKey from '../helpers/EncryptApiKey';

export default function UploadGames() {
  const navigate = useNavigate();
  const { setShowWalletConnectModal } = useContext(AppContext);
  const { address } = useContext(AuthContext);
  const [contractData, setContractData] = useState({
    name: '',
    symbol: '',
  });
  function updateFormData(e) {
    setContractData((prv) => ({ ...prv, [e.target.id]: e.target.value }));
  }
  const [state, dispatch] = useReducer(reducer, initialStates);
  const [isContractDeploy, setIsContractDeploy] = useState(false);

  /** -------------------------------------------------------- */
  const {
    getRootProps: getRootFileProps,
    getInputProps: getInputFileProps,
    acceptedFiles,
    open: openFileInputDialog,
  } = useDropzone({
    accept: '.zip',
    onDrop: (acceptedFiles) => {
      if (!address) {
        setShowWalletConnectModal(true);

        return;
      }

      const totalFiles = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
      dispatch({ type: STATE_ACTIONS.SET_GAME_FILES, payload: totalFiles });
    },
    onDragOver: () => dispatch({ type: STATE_ACTIONS.SET_FILE_DRAG_ACTIVE, payload: true }),
    onDragLeave: () => dispatch({ type: STATE_ACTIONS.SET_FILE_DRAG_ACTIVE, payload: false }),
    noClick: true,
  });

  /** -------------------------------------------------------- */
  const {
    getRootProps: getRootThumbnailProps,
    getInputProps: getInputThumbnailProps,
    open: openThumbnailInputDialog,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      if (!address) {
        setShowWalletConnectModal(true);

        return;
      }

      const file = acceptedFiles[0];
      dispatch({ type: STATE_ACTIONS.SET_THUMBNAIL, payload: file });

      const url = URL.createObjectURL(file);
      dispatch({ type: STATE_ACTIONS.SET_THUMBNAIL_PREVIEW, payload: url });
    },
    onDragOver: () =>
      dispatch({
        type: STATE_ACTIONS.SET_THUMBNAIL_DRAG_ACTIVE,
        payload: true,
      }),
    onDragLeave: () =>
      dispatch({
        type: STATE_ACTIONS.SET_THUMBNAIL_DRAG_ACTIVE,
        payload: false,
      }),
    noClick: true,
  });

  /** -------------------------------------------------------- */
  function checkWalletConnectForFileInput() {
    if (!address) {
      setShowWalletConnectModal(true);
      return;
    }

    openFileInputDialog();
  }

  /** -------------------------------------------------------- */
  function checkWalletConnectForThumbnailInput() {
    if (!address) {
      setShowWalletConnectModal(true);
      return;
    }

    openThumbnailInputDialog();
  }

  /** -------------------------------------------------------- */
  async function uploadOnIPFSHandler(gameName) {
    dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: true });
    dispatch({ type: STATE_ACTIONS.SET_UPLOADING_SERVICE, payload: 'ipfs' });

    const result = await uploadOnIPFS({ gameName, acceptedFiles });

    if (result.success) {
      const dbResponse = await addGameToDatabase(state, address, result.gameUrl);

      if (dbResponse.success) {
        dispatch({ type: STATE_ACTIONS.SET_GAME_URL, payload: result.gameUrl });
        dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: false });
        dispatch({ type: STATE_ACTIONS.SET_GAME_UPLOADED, payload: true });

        toast.success(result.message);
      } else {
        dispatch({ type: STATE_ACTIONS.RESET_STATES });
        toast.error(dbResponse.message);
      }
    } else if (result.reset) {
      dispatch({ type: STATE_ACTIONS.RESET_STATES });
      toast.error(result.message);
    } else {
      toast.error(result.message);

      dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: false });
      dispatch({ type: STATE_ACTIONS.SET_UPLOADING_SERVICE, payload: '' });
    }
  }

  /** -------------------------------------------------------- */
  async function uploadByWeb3Handler(gameName) {
    dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: true });
    dispatch({ type: STATE_ACTIONS.SET_UPLOADING_SERVICE, payload: 'web3' });

    const result = await uploadByWeb3({ gameName, acceptedFiles });

    if (result.success) {
      const dbResponse = await addGameToDatabase(state, address, result.gameUrl);

      if (dbResponse.success) {
        dispatch({ type: STATE_ACTIONS.SET_GAME_URL, payload: result.gameUrl });
        dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: false });
        dispatch({ type: STATE_ACTIONS.SET_GAME_UPLOADED, payload: true });

        toast.success(result.message);
      } else {
        dispatch({ type: STATE_ACTIONS.RESET_STATES });
        toast.error(dbResponse.message);
      }
    } else {
      dispatch({ type: STATE_ACTIONS.RESET_STATES });
      toast.error(result.message);
    }
  }

  /** -------------------------------------------------------- */
  async function uploadOnLighthouseHandler(gameName) {
    dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: true });
    dispatch({
      type: STATE_ACTIONS.SET_UPLOADING_SERVICE,
      payload: 'lighthouse',
    });

    const result = await uploadOnLighthouse({ gameName, acceptedFiles });

    if (result.success) {
      const dbResponse = await addGameToDatabase(state, address, result.gameUrl);

      if (dbResponse.success) {
        dispatch({ type: STATE_ACTIONS.SET_GAME_URL, payload: result.gameUrl });
        dispatch({ type: STATE_ACTIONS.SET_UPLOADING, payload: false });
        dispatch({ type: STATE_ACTIONS.SET_GAME_UPLOADED, payload: true });

        toast.success(result.message);
      } else {
        dispatch({ type: STATE_ACTIONS.RESET_STATES });
        toast.error(dbResponse.message);
      }
    } else {
      dispatch({ type: STATE_ACTIONS.RESET_STATES });
      toast.error(result.message);
    }
  }
  function EncryptAndNavigate(url) {
    var encryptUrl = btoa(url);
    navigate(`/play-game/${encryptUrl}`);
  }
  /** -------------------------------------------------------- */
  /** -------------------Deploy contract---------------------- */
  async function deploySmartContract() {
    const { name, symbol } = contractData;
    const encryptKey = await EncryptApiKey();

    const response = await fetch(process.env.REACT_APP_BACKEND_HOST + `game-detail?gameUrl=${state.gameUrl}`, {
      headers: { 'x-api-key': encryptKey },
    });
    const resData = await response.json();
    if (resData.success) {
      try {
        setIsContractDeploy(true);
        await DeployAndVerifyContract(
          name,
          symbol,
          `ipfs://${resData.data[0].metadata}/metadata.json`,
          state.gameName,
          address,
          state.gameUrl,
        );
        setIsContractDeploy(false);
      } catch (err) {
        setIsContractDeploy(false);
        toast.warning(err.message);
      }
    } else {
      toast.error('Something Wrong Please Try Again Later');
    }
  }
  /** -------------------------------------------------------- */
  const removeUploadedFile = (event) => {
    event.stopPropagation();
    dispatch({
      type: STATE_ACTIONS.SET_GAME_FILES,
      payload: null,
    });
  };

  const removeUploadedThumbnail = (event) => {
    event.stopPropagation();
    dispatch({ type: STATE_ACTIONS.SET_THUMBNAIL, payload: null });
    dispatch({
      type: STATE_ACTIONS.SET_THUMBNAIL_PREVIEW,
      payload: null,
    });
  };

  return (
    <>
      <main className="upload">
        <div className="upload_leftContainer">
          <h1 className="upload_leftContainer_pageHeading">Time to unlock true digital ownership</h1>
          <p className="upload_leftContainer_pageMsg">Please upload .zip of WebGL build </p>
          <div
            {...getRootFileProps({
              className: 'upload_leftContainer_dropzone gradient-border',
            })}
          >
            {state.gameUploaded ? (
              <GameUploadComplete gameFiles={state.gameFiles} uploadingService={state.uploadingService} />
            ) : (
              <>
                {state.uploading ? (
                  <GameUploading uploadingService={state.uploadingService} />
                ) : (
                  <>
                    {state.gameFiles ? (
                      <>
                        <span className="upload_icons">
                          <RiFolderZipFill color="#FFFFFF" />
                          <button className="closeBtn" onClick={(event) => removeUploadedFile(event)}>
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line
                                x1="1.50578"
                                y1="15.7116"
                                x2="15.9245"
                                y2="1.29292"
                                stroke="white"
                                stroke-width="2"
                              />
                              <line
                                x1="1.70711"
                                y1="1.40971"
                                x2="16.1258"
                                y2="15.8284"
                                stroke="white"
                                stroke-width="2"
                              />
                            </svg>
                          </button>
                        </span>
                        <ul className="upload_leftContainer_dropzone_gameFilesList">{state.gameFiles}</ul>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={checkWalletConnectForFileInput}
                          className="upload_leftContainer_dropzone_clickEventController"
                        ></button>
                        <input className="upload_leftContainer_dropzone_inputzone" {...getInputFileProps()} />
                        <span className="upload_icons upload_leftContainer_dropzone_dragDropIcon">
                          <RiDragDropLine />
                        </span>
                        {state.fileDragActive ? (
                          <p className="upload_leftContainer_dropzone_dragDropMsg">Release to drop the files here</p>
                        ) : (
                          <p className="upload_leftContainer_dropzone_dragDropMsg">Drag and drop your game</p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {state.uploading || state.gameUploaded ? (
            <div className="upload_leftContainer_uploadBtns">
              <Button
                text="Launch Game"
                onclick={() => {
                  UploadGAFunctions.launchEvent();
                  dispatch({ type: STATE_ACTIONS.RESET_STATES });
                  EncryptAndNavigate(state.gameUrl);
                }}
                disabled={!state.gameUploaded}
              />
              <Button
                text={isContractDeploy ? 'Deploying Contract...' : 'Launch As NFT'}
                onclick={() => deploySmartContract()}
                disabled={isContractDeploy || !state.gameUploaded}
              />
            </div>
          ) : (
            <div className="upload_leftContainer_uploadBtns">
              <Button
                text="Upload Game on IPFS"
                onclick={() => {
                  uploadOnIPFSHandler(state.gameName);
                  UploadGAFunctions.uploadOnIPFSEvent();
                }}
                disabled={
                  !state.gameFiles ||
                  !state.thumbnailPreview ||
                  !state.gameName ||
                  !state.category ||
                  !state.gameDescription
                }
              />
              {/* <Button
                text="Upload By Web3 Storage"
                onclick={() => {
                  uploadByWeb3Handler(state.gameName);
                  UploadGAFunctions.uploadByWeb3Event();
                }}
                disabled={
                  !state.gameFiles ||
                  !state.thumbnailPreview ||
                  !state.gameName ||
                  !state.category ||
                  !state.gameDescription
                }
              /> */}
              {/* <Button
                text="Upload On Lighthouse"
                onclick={() => {
                  uploadOnLighthouseHandler(state.gameName);
                }}
                disabled={
                  !state.gameFiles ||
                  !state.thumbnailPreview ||
                  !state.gameName ||
                  !state.category ||
                  !state.gameDescription
                }
              /> */}
            </div>
          )}
        </div>
        <div className="upload_rightContainer gradient-border">
          <p className="upload_rightContainer_containerHeading">Launch your game on IPFS</p>
          <div
            {...getRootThumbnailProps({
              className: 'upload_rightContainer_dropzone gradient-border',
            })}
          >
            {state.thumbnailPreview ? (
              <>
                <button className="closeBtn" onClick={(event) => removeUploadedThumbnail(event)}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.50578" y1="15.7116" x2="15.9245" y2="1.29292" stroke="white" stroke-width="2" />
                    <line x1="1.70711" y1="1.40971" x2="16.1258" y2="15.8284" stroke="white" stroke-width="2" />
                  </svg>
                </button>
                <span className="upload_rightContainer_dropzone_imgPreview">
                  <img src={state.thumbnailPreview} alt="preview" />
                </span>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    UploadGAFunctions.thumbnailUploadEvent();
                    checkWalletConnectForThumbnailInput();
                  }}
                  className="upload_rightContainer_dropzone_clickEventController"
                ></button>
                <input className="upload_rightContainer_dropzone_inputzone" {...getInputThumbnailProps()} />
                <span className="upload_icons upload_rightContainer_dropzone_dragDropIcon">
                  <RiDragDropLine />
                </span>
                {state.thumbnailDragActive ? (
                  <p className="upload_rightContainer_dropzone_dragDropMsg">Release to drop the files here</p>
                ) : (
                  <p className="upload_rightContainer_dropzone_dragDropMsg">Upload game thumbnail</p>
                )}
              </>
            )}
          </div>
          <div className="upload_rightContainer_nameInputGroup">
            <label htmlFor="name" className="upload_rightContainer_nameInputGroup_nameLabel">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              readOnly={state.uploading || state.gameUploaded}
              value={state.gameName}
              onChange={(event) =>
                dispatch({
                  type: STATE_ACTIONS.SET_GAME_NAME,
                  payload: event.target.value,
                })
              }
              className="upload_rightContainer_nameInputGroup_nameInput gradient-border"
            />
          </div>
          <div className="upload_rightContainer_categoryInputGroup">
            <label htmlFor="categoryName" className="upload_rightContainer_categoryInputGroup_categoryLabel">
              Category
            </label>
            <select
              className="upload_rightContainer_categoryInputGroup_categoryInput gradient-border"
              id="categoryName"
              name="categoryName"
              defaultValue=""
              onChange={(event) =>
                dispatch({
                  type: STATE_ACTIONS.SET_CATEGORY,
                  payload: event.target.value,
                })
              }
            >
              <option value="" disabled={true}>
                Select Game Category
              </option>
              {gameCategories.map(function (category, _id) {
                return (
                  <option key={_id} value={category.value}>
                    {category.option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="upload_rightContainer_descriptionInputGroup">
            <label htmlFor="description" className="upload_rightContainer_descriptionInputGroup_descriptionLabel">
              Description
            </label>
            <textarea
              name="name"
              id="name"
              rows={6}
              readOnly={state.uploading || state.gameUploaded}
              value={state.gameDescription}
              onChange={(event) =>
                dispatch({
                  type: STATE_ACTIONS.SET_GAME_DESCRIPTION,
                  payload: event.target.value,
                })
              }
              className="upload_rightContainer_descriptionInputGroup_descriptionInput gradient-border"
            ></textarea>
          </div>
          {state.gameUploaded ? (
            <>
              <div className="upload_rightContainer_nameInputGroup">
                <label htmlFor="name" className="upload_rightContainer_nameInputGroup_nameLabel">
                  Game Contract Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={contractData.name}
                  onChange={(e) => updateFormData(e)}
                  className="upload_rightContainer_nameInputGroup_nameInput gradient-border"
                />
              </div>
              <div className="upload_rightContainer_nameInputGroup">
                <label htmlFor="name" className="upload_rightContainer_nameInputGroup_nameLabel">
                  Game Token Symbol
                </label>
                <input
                  type="text"
                  name="name"
                  id="symbol"
                  value={contractData.symbol}
                  onChange={(e) => updateFormData(e)}
                  className="upload_rightContainer_nameInputGroup_nameInput gradient-border"
                />
              </div>
            </>
          ) : null}
        </div>
      </main>
    </>
  );
}
