import ReactGA from 'react-ga4';

export const homeGAFunctions = {
  viewAllButtonEvent: function () {
    ReactGA.event({
      action: 'View All Games',
      category: 'Games',
    });
  },
  registerEvent: function () {
    ReactGA.event({
      action: 'Register',
      category: 'Game Jam',
    });
  },
  allGames: function () {
    ReactGA.event({
      action: 'All Games',
      category: 'Game',
    });
  },
  p2EGames: function () {
    ReactGA.event({
      action: 'P2E Games',
      category: 'Game',
    });
  },
  hyperCasualGames: function () {
    ReactGA.event({
      action: 'HyperCasual Games',
      category: 'Game',
    });
  },
  casualGames: function () {
    ReactGA.event({
      action: 'Casual Games',
      category: 'Game',
    });
  },
  rpgGames: function () {
    ReactGA.event({
      action: 'RPG Games',
      category: 'Game',
    });
  },
  racingGames: function () {
    ReactGA.event({
      action: 'Racing Games',
      category: 'Game',
    });
  },
};
