import { toast } from "react-toastify";
import CheckChain from "./CheckChain";

export async function walletConnect(setShowWalletConnectModal) {
  const { ethereum } = window;
  let currentAccount = null;
  let metamaskProvider;
  try {
    if (!ethereum) {
      toast.error("Metamask not detected, Please download Metamask!!");
      return;
    }
    if (window.ethereum.providers === undefined) {
      metamaskProvider = window.ethereum;
    } else {
      metamaskProvider = window.ethereum.providers.find(
        (provider) => provider.isMetaMask
      );
    }

    await CheckChain();
    let accounts;
    try {
      accounts = await metamaskProvider.request({
        method: "eth_requestAccounts",
      });
    } catch (error) {
      console.log(error);
      return;
    }

    currentAccount = accounts[0];
    localStorage.setItem("walletAddress", currentAccount);
    toast.success("Wallet connected successfully \n");
    setShowWalletConnectModal(false);

    return currentAccount;
  } catch (error) {
    let errordata = error.data
      ? error.data.message.split(":")[1]
      : error.message.split(":")[1];
    toast.error("Please connect your wallet \n");
    console.log("Error:", errordata);
  }
}
