import { backendRoutes } from "../constants/routes";
import EncryptApiKey from "./EncryptApiKey";

export default async function UpdateGameCounterApi(name, walletAddress) {
  const encryptKey = await EncryptApiKey();

  const body = JSON.stringify({
    deployedGameName: name,
    walletAddress: walletAddress,
  });
  const response = await fetch(backendRoutes.updateGamePlayCounter, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": encryptKey,
    },
    body: body,
  });
}
