import { ethers } from 'ethers';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BsTwitter } from 'react-icons/bs';
import { FaPlay } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Unity, useUnityContext } from 'react-unity-webgl';
import web3 from 'web3';
import { playGameGAFunctions } from '../GAFunctions/PlayGameGA';
import { appImages } from '../constants/images';
import { AuthContext } from '../context/AuthContext';
import { client, gql } from '../contractInteractions/ApolloClient';
import BuyNFT from '../contractInteractions/BuyNFT';
import CancelListing from '../contractInteractions/CancelListing';
import FetchABI from '../contractInteractions/GetABI';
import ListNFT from '../contractInteractions/ListNFT';
import SignerFn from '../contractInteractions/SignerFn';
import DeployAndVerifyContract from '../helpers/DeployAndVerifyContract';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt.ts';
import { SliseAd } from '@slise/embed-react';
import UpdateGameCounterApi from '../helpers/UpdateGameCounterApi';
import { useDropzone } from 'react-dropzone';
import { RiDragDropLine, RiFolderZipFill } from 'react-icons/ri';
import { uploadOnIPFSUpdate } from '../components/upload/UploadFunctions';
import { GameUploadComplete, GameUploading } from '../components/upload/UploadScreens';
import { backendRoutes } from '../constants/routes';
import EncryptApiKey from '../helpers/EncryptApiKey';

export default function PlayGameNew() {
  const [gameStartState, setGameStartState] = useState(false);
  const { address } = useContext(AuthContext);
  const [gameData, setGameData] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [gameType, setGameType] = useState(false);
  const [fetchDataName, SetFetchDataName] = useState('');
  const [nftOwner, setNftOwner] = useState('');
  const [isListed, setListed] = useState(false);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [listingPrice, SetListingPrice] = useState(0);
  const [showContractDeployForm, setShowContractDeployForm] = useState(false);
  const [orderId, SetOrderId] = useState('');
  const [gameUploadActive, setGameUploadActive] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [gameUploadingStatus, setGameUploadingStatus] = useState('upload');
  const [fileDragActive, setFileDragActive] = useState(false);
  const [gameFiles, setGameFiles] = useState(null);
  const [iscontractDeploy, setIsContractDeploy] = useState(false);
  const [btnDisable, setBtndisable] = useState(false);

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

  const navigate = useNavigate();
  const sharedURL = window.location.href;
  const {
    getRootProps: getRootFileProps,
    getInputProps: getInputFileProps,
    acceptedFiles,
    open: openFileInputDialog,
  } = useDropzone({
    accept: '.zip',
    onDrop: (acceptedFiles) => {
      const totalFiles = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>);
      setGameFiles(totalFiles);
    },
    onDragOver: () => setFileDragActive(true),
    onDragLeave: () => setFileDragActive(false),
  });

  const { url } = useParams();

  let originalText = atob(url);
  async function GetNFTDetails(gameData) {
    const DataQuery = `{
      marketplaceOrders(where:{nftAddress: "${gameData.contractAddress}",tokenId:${gameData.tokenId},status:ACTIVE}) {
        id
        status
        nftAddress
        nftType
        tokenId
        quantity
        pricePerItem
        seller
      }
    }`;

    const abi = await FetchABI(gameData.contractAddress, 'polygon');
    const nftContract = new ethers.Contract(gameData.contractAddress, abi, SignerFn);
    const symbol = await nftContract.symbol();
    setTokenSymbol(symbol);
    let responseData = await client.query({
      query: gql(DataQuery),
    });

    let listingprice;
    if (responseData.data.marketplaceOrders.length == 0) {
      listingprice = responseData.length;
    } else {
      listingprice = web3.utils.fromWei(responseData.data.marketplaceOrders[0].pricePerItem.toString(), 'ether');
      setListed(true);
      SetOrderId(responseData.data.marketplaceOrders[0].id);
      SetListingPrice(listingprice);
    }
  }
  const playGame = (name) => {
    ReactGA.event({
      action: `Game Played: ${name}`,
      category: 'Games',
      label: `/play-game/${url}`,
    });
  };

  async function DataFetch() {
    let url;
    if (originalText.includes('spheron.app')) {
      url = `https://${originalText}/spheron-manifest.json`;
    } else {
      url = originalText + '/spheron-manifest.json';
    }

    const res = await fetch(url);
    const resData = await res.json();

    if (!resData.directoryStructure.includes('index.js')) {
      for (let index = 0; index < resData.directoryStructure.length; index++) {
        const element = resData.directoryStructure[index];
        if (element.includes('.framework.js') && element.includes('Build/')) {
          if (!element.includes('.framework.js.gz')) {
            setGameType(true);
            let newElement = element.replace('Build/', '');
            let newElement1 = newElement.replace('.framework.js', '');
            SetFetchDataName(newElement1);
            break;
          }
        } else {
          setGameType(false);
        }
      }
    }
  }

  function getFormattedDate(date) {
    return date.split('-').reverse().join('-');
  }
  async function fetchData() {
    const encryptKey = await EncryptApiKey();

    const res = await fetch(process.env.REACT_APP_BACKEND_HOST + `game-detail?gameUrl=${originalText}`, {
      headers: { 'x-api-key': encryptKey },
    });
    const resData = await res.json();
    if (resData.success) {
      setGameData(resData.data[0]);
      if (resData.data[0].contractAddress) {
        GetNFTDetails(resData.data[0]);
        getAddressFromContract(resData.data[0].contractAddress);
      }
    }
    setLoading(false);
  }

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener('change', updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
      };
    },
    [devicePixelRatio],
  );

  async function getAddressFromContract(contractAddress) {
    const res = await fetch(
      `https://polygon-mumbai.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY}/getOwnersForToken/?contractAddress=${contractAddress}&tokenId=1`,
    );
    const resData = await res.json();
    if (resData?.owners[0]) {
      setNftOwner(resData?.owners[0]);
    }
  }

  async function deployContractHandler(event) {
    event.preventDefault();
    const form = event.target;

    const contractName = form.contractName.value;
    const tokenSymbol = form.tokenSymbol.value;

    if (contractName.split(' ').length > 1 || tokenSymbol.split(' ').length > 1) {
      toast.error('Inavlid Inputs! White Spaces are not Allowed!');
      return;
    }

    try {
      setIsContractDeploy(true);
      await DeployAndVerifyContract(
        contractName,
        tokenSymbol,
        `ipfs://${gameData.metadata}/metadata.json`,
        gameData.name,
        address,
        gameData.gameUrl,
      );
      setIsContractDeploy(false);
    } catch (err) {
      setIsContractDeploy(false);
      toast.warning(err.message);
    }

    // toast.success("Successfully Deployed!");
  }

  async function DeleteGame() {
    const encryptKey = await EncryptApiKey();

    const body = JSON.stringify({
      deployedGameName: gameData.name,
      walletAddress: address,
    });
    const response = await fetch(backendRoutes.deleteGame, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': encryptKey,
      },
      body: body,
    });
    const resData = await response;
    if (resData.status === 204) {
      toast.success('Game successfully removed from portal');
    } else {
      toast.success('Something went wrong!!');
    }
    navigate('/');
    toast.success(resData.msg);
  }
  function EncryptAndNavigate(url) {
    var encryptUrl = btoa(url);
    navigate(`/play-game/${encryptUrl}`, { replace: true });
    window.location.reload();
  }
  async function UploadUpdatedVersion() {
    setGameUploadingStatus('upload');
    if (gameFiles === null) {
      setGameUploadActive(true);
    } else {
      setGameUploadingStatus('uploading');
      let gameName = gameData.name;
      const result = await uploadOnIPFSUpdate({ gameName, acceptedFiles });
      if (result.success) {
        const encryptKey = await EncryptApiKey();

        const body = JSON.stringify({
          deployedGameName: gameData.name,
          walletAddress: address,
          gameUrl: result.gameUrl,
        });
        const response = await fetch(backendRoutes.updateGame, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': encryptKey,
          },
          body: body,
        });
        const resData = await response;
        if (resData.status === 200) {
          toast.success('Game updated successfully');
          EncryptAndNavigate(result.gameUrl);
        } else {
          toast.success('Something went wrong!!');
        }
        setGameUploadingStatus('uploaded');
      }
    }
  }
  async function updateGamePlayCounter() {
    if (!gameType) {
      await UpdateGameCounterApi(gameData.name, gameData.createdBy);
    } else {
      return;
    }
  }

  useEffect(() => {
    if (originalText) {
      fetchData();
      handleChangePixelRatio();
      DataFetch();
    }
  }, [originalText]);

  async function deleteConfirmationHandler(event, status) {
    if (!status) {
      setshowDeleteModal(false);
      return;
    } else {
      DeleteGame();
    }
  }
  return (
    <>
      {!isLoading ? (
        <>
          <div className="game-as-nft">
            <div className="game-information-container gradient-border">
              <div className="game-container gradient-border">
                {gameStartState ? (
                  gameType ? (
                    <>
                      {fetchDataName ? (
                        <div className="game-iframe-container">
                          <UnityGame
                            fetchDataName={fetchDataName}
                            originalText={originalText}
                            devicePixelRatio={devicePixelRatio}
                            style={{ width: '53rem', aspectRatio: '9/5' }}
                            gameName={gameData.name}
                            gameCreatedBy={gameData.createdBy}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className="game-iframe-container">
                        <iframe
                          className="game-iframe"
                          title="disjunction"
                          id="myIframe"
                          src={originalText.includes('spheron.app') ? `https://${originalText}` : originalText}
                          width="100%"
                          height="100%"
                          allow="fullscreen"
                        ></iframe>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <span className="thumbnail-image">
                      <img
                        loading="lazy"
                        src={`https://ipfs.io/ipfs/${gameData.thumbnail}`}
                        alt="thumbnail"
                      />
                    </span>
                    <span
                      role={'button'}
                      onClick={() => {
                        playGame(gameData.name);
                        setGameStartState(true);
                        updateGamePlayCounter();
                      }}
                      className="play-btn"
                    >
                      <FaPlay />
                    </span>
                  </>
                )}
              </div>
              <main className="information-container">
                <div className="game-heading">
                  <div className="information">
                    <div className="information-head">
                      <h1 className="game-name">{gameData.name}</h1>
                    </div>
                    <p className="listing-date">
                      Listed on {getFormattedDate(gameData.createdAt.toString().substring(0, 10))}
                    </p>
                    <p className="listing-date">Number of times game played {gameData?.gamePlayedCounter || 0}</p>
                    <div className="creator-info">
                      <p>Created By</p>
                      <div className="creator-avatar">
                        <span className="avatar-image">
                          <img src={appImages.avatar} alt="avatar" />
                        </span>
                        <p title={gameData?.createdBy} className="name">{`${gameData?.createdBy?.substring(
                          0,
                          5,
                        )}....${gameData?.createdBy?.substring(gameData?.createdBy.length - 5)}`}</p>{' '}
                      </div>
                    </div>
                    <a
                      href={`https://twitter.com/intent/tweet?text=Try%20to%20beat%20my%20record%20in%20the%20game,%20which%20is%20available%20by%20this%20link%20-%20${gameData.shortLink}`}
                      target="_blank"
                      rel="noreferrer"
                      data-size="large"
                    >
                      <button className="share-btn">
                        Share <BsTwitter />
                      </button>
                    </a>
                    {gameData?.createdBy === address ? (
                      <div className="game-control-btns">
                        <button onClick={(event) => UploadUpdatedVersion()} className="update-game-btn">
                          Upload Updated Version
                        </button>
                        <button onClick={() => setshowDeleteModal(true)} className="remove-game-btn">
                          Remove from Portal
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {gameData?.createdBy === address ? (
                    <>
                      {!gameData.contractAddress ? (
                        <div className="action">
                          <p className="heading">Mint Game as NFT (Beta Testnet)</p>
                          <button
                            className="action-btn"
                            onClick={() => {
                              playGameGAFunctions.mintEvent();
                              setShowContractDeployForm(true);
                            }}
                          >
                            Mint Now
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {!isListed && nftOwner === address ? (
                    <div className="action">
                      <div className="contract-information">
                        <div className="information-group">
                          <p className="title">Contract Symbol</p>
                          <p className="info">${tokenSymbol}</p>
                        </div>
                        <div className="information-group">
                          <p className="title">Contract Type</p>
                          <p className="info">ERC-721</p>
                        </div>
                      </div>
                      <p className="chain-information">
                        Deployed on Polygon
                        <a
                          href={'https://mumbai.polygonscan.com/address/' + gameData.contractAddress}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          See Details
                        </a>
                      </p>
                      <div className="listing-price-details">
                        <p className="label">List For</p>
                        <div className="price-input-group">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={listingPrice ? listingPrice : ''}
                            onChange={(e) => SetListingPrice(e.target.value)}
                          />
                          Matic
                        </div>
                      </div>
                      <button
                        className="action-btn"
                        disabled={btnDisable}
                        onClick={async () => {
                          try {
                            if (Number(listingPrice) < 0) {
                              toast.warning('Price Must Be Greater Than 0');
                              return;
                            }
                            setBtndisable(true);
                            await ListNFT(gameData?.contractAddress, gameData?.tokenId, listingPrice);
                            setBtndisable(false);
                          } catch (err) {
                            toast.error('Something Wrong');
                            setBtndisable(false);
                          }
                        }}
                      >
                        {btnDisable ? 'Listing Item...' : 'List Now'}
                      </button>
                    </div>
                  ) : null}
                  {isListed ? (
                    nftOwner === address ? (
                      <div className="action">
                        <p className="heading"> Cancel Game (Beta Testnet)</p>
                        <button
                          className="action-btn"
                          disabled={btnDisable}
                          onClick={async () => {
                            playGameGAFunctions.cancelEvent();
                            try {
                              setBtndisable(true);
                              await CancelListing(orderId);
                              setBtndisable(false);
                            } catch (err) {
                              toast.error('Something Wrong');
                              setBtndisable(false);
                            }
                          }}
                        >
                          Cancel Now
                        </button>
                      </div>
                    ) : (
                      <div className="action">
                        {/* <p className="heading">BUY Game as NFT (Beta Testnet)</p> */}
                        <div className="contract-information">
                          <div className="information-group">
                            <p className="title">Contract Symbol</p>
                            <p className="info">${tokenSymbol}</p>
                          </div>
                          <div className="information-group">
                            <p className="title">Contract Type</p>
                            <p className="info">ERC-721</p>
                          </div>
                        </div>
                        <p className="chain-information">
                          Deployed on Polygon
                          <a
                            href={'https://mumbai.polygonscan.com/address/' + gameData.contractAddress}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See Details
                          </a>
                        </p>
                        <div className="listing-price-details">
                          <p className="label">Listed For</p>
                          <p className="listed-price">{listingPrice} Matic</p>
                        </div>
                        <button
                          className="action-btn"
                          disabled={btnDisable}
                          onClick={async () => {
                            playGameGAFunctions.buyEvent();
                            try {
                              setBtndisable(true);
                              await BuyNFT(orderId, listingPrice);
                              setBtndisable(false);
                            } catch (err) {
                              toast.error('Something Wrong');
                              setBtndisable(false);
                            }
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    )
                  ) : null}
                </div>
                {gameUploadActive ? (
                  <div className="dynamic-action-container game-upload-container">
                    {gameUploadingStatus === 'upload' ? (
                      <div
                        {...getRootFileProps({
                          className: 'upload-dropzone',
                        })}
                      >
                        <>
                          <input className="upload-inputzone" {...getInputFileProps()} />
                          {gameFiles ? (
                            <>
                              <span className="upload_icons">
                                <RiFolderZipFill color="#FFFFFF" />
                              </span>
                              <ul className="game-files-list">{gameFiles}</ul>
                            </>
                          ) : (
                            <>
                              <span className="upload_icons">
                                <RiDragDropLine />
                              </span>
                              {fileDragActive ? (
                                <p className="dragDropMsg">Release to drop the files here</p>
                              ) : (
                                <>
                                  <p className="dragDropMsg">Drag and drop or Upload new game build</p>{' '}
                                </>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    ) : null}
                    {gameUploadingStatus === 'uploading' ? (
                      <div className="game-uploading">
                        <GameUploading uploadingService={'ipfs'} />
                      </div>
                    ) : null}
                    {gameUploadingStatus === 'uploaded' ? (
                      <div className="game-uploading">
                        <GameUploadComplete
                          gameFiles={gameFiles}
                          message={'New build uploaded successfully'}
                          uploadingService={'ipfs'}
                        />
                        <button className="launch-game-btn">Launch Game</button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {showContractDeployForm ? (
                  <div className="contract-details-form dynamic-action-container">
                    <h3 className="details-heading">Share Contract Details</h3>
                    <form className="form" onSubmit={(event) => deployContractHandler(event)}>
                      <div className="form-controll-group-container">
                        <div className="form-controll-group">
                          <label htmlFor="contractName" className="label">
                            Game Contract Name
                          </label>
                          <textarea
                            type="text"
                            name="contractName"
                            id="contractName"
                            required={true}
                            className="textarea gradient-border"
                          />
                        </div>
                        <div className="form-controll-group">
                          <label htmlFor="tokenSymbol" className="label">
                            Game Token Symbol
                          </label>
                          <textarea
                            type="text"
                            name="tokenSymbol"
                            id="tokenSymbol"
                            required={true}
                            className="textarea gradient-border"
                          />
                        </div>
                      </div>
                      <button type="submit" className="submit-btn" disabled={iscontractDeploy}>
                        {iscontractDeploy ? 'Deploying Contract...' : 'Deploy Now'}
                      </button>
                    </form>
                  </div>
                ) : null}
                <div className="game-description">
                  <h2 className="heading">Description</h2>
                  <p className="description-text">{gameData?.description}</p>
                </div>
                <SliseAd slotId="1" pub="pub-5" format="728x90" style={{ width: '728px', height: '90px' }} />
              </main>
            </div>
            {showDeleteModal ? (
              <main className="modal alertModal">
                <div className="modal_modalContainer" onClick={(event) => event.stopPropagation()}>
                  <p className="alertMsg">Do you want to delete?</p>
                  <div className="btns-container">
                    <button onClick={(event) => deleteConfirmationHandler(event, true)}>Yes</button>
                    <button onClick={(event) => deleteConfirmationHandler(event, false)}>No</button>
                  </div>
                </div>
              </main>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </>
  );
}

function UnityGame({ fetchDataName, originalText, gameName, gameCreatedBy }) {
  const fetchDataNameArray = fetchDataName.split('.');
  let gamaeUrl;
  if (originalText.includes('spheron.app')) {
    gamaeUrl = `https://${originalText}`;
  } else {
    gamaeUrl = originalText;
  }
  const { unityProvider, isLoaded, loadingProgression, requestFullscreen, unload } = useUnityContext({
    loaderUrl: `${gamaeUrl}/Build/${fetchDataNameArray[0]}.loader.js`,
    dataUrl: `${gamaeUrl}/Build/${fetchDataNameArray[0]}.data${
      fetchDataNameArray[1] ? `.${fetchDataNameArray[1]}` : ''
    }`,
    frameworkUrl: `${gamaeUrl}/Build/${fetchDataNameArray[0]}.framework.js${
      fetchDataNameArray[1] ? `.${fetchDataNameArray[1]}` : ''
    }`,
    codeUrl: `${gamaeUrl}/Build/${fetchDataNameArray[0]}.wasm${
      fetchDataNameArray[1] ? `.${fetchDataNameArray[1]}` : ''
    }`,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isLoaded);
  const { url } = useParams();
  async function exitConfirmationHandler(event, status) {
    if (!status) {
      cancelNavigation();
      return;
    }

    await unload();
    confirmNavigation();
  }
  const quitGame = () => {
    ReactGA.event({
      action: 'Quit Game',
      category: 'Games',
      label: `/play-game/${url}`,
    });
  };
  const denyQuitGame = () => {
    ReactGA.event({
      action: 'Denied Quit Game',
      category: 'Games',
      label: `/play-game/${url}`,
    });
  };

  useEffect(() => {
    if (isLoaded) {
      UpdateGameCounterApi(gameName, gameCreatedBy);
    }
  }, [isLoaded]);
  return (
    <Fragment>
      {/* {showPrompt ? <button onClick={() => setShowAlert(true)}>onClick</button> : null} */}
      {!isLoaded ? (
        <div className="loader">
          <div style={{ width: `${Math.round(loadingProgression * 100)}%` }}></div>
        </div>
      ) : null}
      <Unity
        unityProvider={unityProvider}
        style={{ width: '53rem', aspectRatio: '9/5' }}
        devicePixelRatio={devicePixelRatio}
      />
      {isLoaded ? (
        <button className="full-screen-btn" onClick={() => requestFullscreen(true)}>
          <span className="full-screen-icon">
            <AiOutlineFullscreen />
          </span>
        </button>
      ) : null}
      {showPrompt && isLoaded ? (
        <main className="modal alertModal">
          <div className="modal_modalContainer" onClick={(event) => event.stopPropagation()}>
            <p className="alertMsg">Do you really want to quit?</p>
            <div className="btns-container">
              <button
                onClick={(event) => {
                  quitGame();
                  exitConfirmationHandler(event, true);
                }}
              >
                Yes
              </button>
              <button
                onClick={(event) => {
                  denyQuitGame();
                  exitConfirmationHandler(event, false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </main>
      ) : null}
    </Fragment>
  );
}
