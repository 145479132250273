import { NFTStorage } from "nft.storage";
import { backendRoutes } from "../../constants/routes";
import EncryptApiKey from "../../helpers/EncryptApiKey";

/** -------------------------------------------------------- */

async function checkGameName(gameName) {
  try {
    const encryptKey = await EncryptApiKey();

    const response = await fetch(backendRoutes.checkGameName, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": encryptKey },
      body: JSON.stringify({ projectName: gameName }),
    });

    const data = await response.json();
    if (data.status) {
      return {
        success: true,
        message: "",
      };
    } else {
      return {
        success: false,
        message: data.msg,
      };
    }
  } catch (error) {
    console.error(error.message);
  }
}

/** -------------------------------------------------------- */

export async function uploadImageOnNFTStorage(options) {
  const { image, name, description, gameUrl } = options;

  const nftStorage = new NFTStorage({
    token: process.env.REACT_APP_NFT_STORAGE_KEY,
  });

  try {
    const result = await nftStorage.store({
      image,
      name,
      description,
      game_url: gameUrl,
      animation_url: gameUrl,
    });
    const thumbnailPathname = result.data.image.pathname;
    const thumbnailCID = thumbnailPathname.slice(2);

    const metadataUrl = result.url;
    const metadataCID = metadataUrl.split("/")[2];

    const thumbnailData = {
      metadataCID: metadataCID,
      thumbnailCID: thumbnailCID,
    };

    return {
      status: true,
      thumbnailData: thumbnailData,
    };
  } catch (error) {
    console.error(error.message);

    return {
      status: false,
      error: error.message,
    };
  }
}

/** -------------------------------------------------------- */

export async function addGameToDatabase(state, address, gameUrl) {
  let thumbnailData;

  const image = state.thumbnail;
  const name = state.gameName;
  const description = state.gameDescription;

  try {
    const thumbnailUploadResponse = await uploadImageOnNFTStorage({
      image,
      name,
      description,
      gameUrl,
    });

    if (!thumbnailUploadResponse.status) {
      return {
        success: false,
        message:
          "Error in thumbnail uploading: " + thumbnailUploadResponse.error,
      };
    }

    thumbnailData = thumbnailUploadResponse.thumbnailData;
  } catch (error) {
    console.error(error.message);
  }

  const { metadataCID, thumbnailCID } = thumbnailData;

  const gameData = {
    _id: address,
    data: {
      deployedGames: [
        {
          name: state.gameName,
          url: gameUrl,
          thumbnail: thumbnailCID,
          metadata: metadataCID,
          category: state.category,
          description: description,
          walletAddress: address,
          createdBy: address,
          createdAt: Date.now(),
        },
      ],
    },
  };
  const encryptKey = await EncryptApiKey();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-api-key": encryptKey,
    },
    body: JSON.stringify(gameData),
  };

  try {
    const response = await fetch(backendRoutes.addGame, requestOptions);

    if (response.ok) {
      return {
        success: true,
        message: "",
      };
    } else {
      return {
        success: false,
        message: "Something went wrong! Try again later.",
      };
    }
  } catch (error) {
    console.error(error.message);
  }
}

/** -------------------------------------------------------- */

export async function uploadOnIPFS(options) {
  const { gameName, acceptedFiles } = options;
  const { success, message } = await checkGameName(gameName);

  if (!success) {
    return {
      success: false,
      reset: false,
      message: message,
    };
  }

  const formData = new FormData();
  for (let i = 0; i < acceptedFiles.length; i++) {
    formData.append("file", acceptedFiles[i]);
  }

  try {
    const encryptKey = await EncryptApiKey();

    const response = await fetch(`${backendRoutes.uploadOnIPFS}/${gameName}`, {
      method: "POST",
      headers: {
        Accept: "multipart/form-data",
        "x-api-key": encryptKey,
      },
      body: formData,
    });

    const data = await response.json();
    if (data.status) {
      return {
        success: true,
        gameUrl: data.msg.dynamicLinks[0]
          ? data.msg.dynamicLinks[0]
          : data.msg.sitePreview,
        message: "Game Uploaded Successfully on IPFS",
      };
    } else {
      return {
        success: false,
        reset: true,
        message: data?.msg?.message || data?.msg,
      };
    }
  } catch (error) {
    console.error(error.message);

    return {
      success: false,
      reset: true,
      message: error.message,
    };
  }
}

/** -------------------------------------------------------- */

export async function uploadByWeb3(options) {
  const { gameName, acceptedFiles } = options;

  const formData = new FormData();
  for (let i = 0; i < acceptedFiles.length; i++) {
    formData.append("file", acceptedFiles[i]);
  }

  try {
    const encryptKey = await EncryptApiKey();

    const response = await fetch(`${backendRoutes.uploadByWeb3}/${gameName}`, {
      method: "POST",
      headers: {
        Accept: "multipart/form-data",
        "x-api-key": encryptKey,
      },
      body: formData,
    });

    const data = await response.json();

    if (data.status) {
      return {
        success: true,
        gameUrl: "https://ipfs.io/ipfs/" + data.msg,
        message: "Game Uploaded Successfully on Web3 Storage",
      };
    } else {
      return {
        success: false,
        message: "Error in Game Uploading on Web3 Storage",
      };
    }
  } catch (error) {
    console.error(error.message);

    return {
      success: false,
      message: error.message,
    };
  }
}
/** -------------------------------------------------------- */

export async function uploadOnIPFSUpdate(options) {
  const { gameName, acceptedFiles } = options;

  const formData = new FormData();
  for (let i = 0; i < acceptedFiles.length; i++) {
    formData.append("file", acceptedFiles[i]);
  }

  try {
    const encryptKey = await EncryptApiKey();

    const response = await fetch(`${backendRoutes.uploadOnIPFS}/${gameName}`, {
      method: "POST",
      headers: {
        Accept: "multipart/form-data",
        "x-api-key": encryptKey,
      },
      body: formData,
    });

    const data = await response.json();
    if (data.status) {
      return {
        success: true,
        gameUrl: data.msg.dynamicLinks[0]
          ? data.msg.dynamicLinks[0]
          : data.msg.sitePreview,
        message: "Game Uploaded Successfully on IPFS",
      };
    } else {
      return {
        success: false,
        reset: true,
        message: data?.msg?.message || data?.msg,
      };
    }
  } catch (error) {
    console.error(error.message);

    return {
      success: false,
      reset: true,
      message: error.message,
    };
  }
}

/** -------------------------------------------------------- */

/** -------------------------------------------------------- */

export async function uploadOnLighthouse(options) {
  const { gameName, acceptedFiles } = options;

  const formData = new FormData();
  for (let i = 0; i < acceptedFiles.length; i++) {
    formData.append("file", acceptedFiles[i]);
  }

  try {
    const encryptKey = await EncryptApiKey();

    const response = await fetch(
      `${backendRoutes.uploadOnLighthouse}/${gameName}`,
      {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
          "x-api-key": encryptKey,
        },
        body: formData,
      }
    );

    const data = await response.json();

    if (data.status) {
      return {
        success: true,
        gameUrl: "https://lync.lighthouse.storage/ipfs/" + data.msg.data.Hash,
        message: "Game Uploaded Successfully on Lighthouse",
      };
    } else {
      return {
        success: false,
        message: "Error in Game Uploading on Lighthouse",
      };
    }
  } catch (error) {
    console.error(error.message);

    return {
      success: false,
      message: error.message,
    };
  }
}
