import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const [address, setAddress] = useState(null);

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      toast.error('Metamask not detected');

      console.log('METAMASK NOT DETECTED');
      // USE TOASTER
      return;
    }
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    // TODO (eth_requestAccounts) -> to open popup
    window.ethereum.on('accountsChanged', async () => {
      window.location.reload();
    });
    const localAddress = localStorage.getItem('walletAddress');
    if (accounts.length > 0 && localAddress !== accounts[0]) {
      window.location.reload();
    }
    if (accounts && accounts.length > 0) {
      localStorage.setItem('walletAddress', accounts[0]);
      setAddress(accounts[0]);
    } else {
      localStorage.clear();
      setAddress(null);
    }
  };
  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        address,
        setAddress,
      }}
    >
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
