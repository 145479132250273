export default async function EncryptApiKey() {
  const privateKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const currentTime = new Date().getTime().toString();
  const data = {
    apiKey: process.env.REACT_APP_X_API_KEY,
    timestamp: currentTime,
  };

  const importedPrivateKey = await crypto.subtle.importKey(
    "pkcs8",
    new Uint8Array(
      atob(privateKey)
        .split("")
        .map((c) => c.charCodeAt(0))
    ),
    {
      name: "ECDSA",
      namedCurve: "P-256",
    },
    true,
    ["sign"]
  );
  const signature = await window.crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" },
    },
    importedPrivateKey,
    new TextEncoder().encode(JSON.stringify(data))
  );
  const signatureToSend = btoa(
    String.fromCharCode(...new Uint8Array(signature))
  );

  return `${signatureToSend}**${process.env.REACT_APP_PROJECT_ID}**${currentTime}`;
}
